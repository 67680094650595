<template>
    <div>
        <List
            title="licensing options"
            resource=""
            ref="list"
            :data="licensing"
            :fields="fields"
            track-by="bundleId"
            :detailRow="detailRow"
        >
            <template v-slot:detail-toggler="{record, isOpen, toggleRow}">
                <TogglerButton v-if="record.checked" :isOpen="isOpen" @click="toggleRow"/>
            </template>
        </List>
    </div>
</template>

<script>
import ValidatorMixin from '@/components/form/ValidatorMixin';
import ConfirmationMixin from '@/mixins/ConfirmationMixin';
import List from '@/components/auth/list/List';
import CheckField from '@/components/auth/list/fields/CheckField';
import AdvancedLicensingRow from "@/components/auth/list/detailrows/AdvancedLicensingRow";
import TogglerButton from "@/components/auth/list/TogglerButton";

export default {
    name: 'CommunityLicensing',
    components: {
        List,
        TogglerButton,
    },
    mixins: [ValidatorMixin, ConfirmationMixin],
    props: {
        data: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    emits: ['update'],
    data: function () {
        return {
            customerBundles: [],
            licensing: [],
            fields: [
                {
                    name: CheckField,
                    title: 'applications list',
                    switch: {
                        label: 'bundleName',
                        onChange: this.onLicensingCheck,
                        onInput: this.onLicensingInput,
                        disabled: (data) => data.disabled,
                        disabledReason: 'Maximum number of communities is reached',
                    },
                },
                {
                    name: 'detail-toggler',
                    title: 'advanced options',
                    titleClass: ' text-right',
                    dataClass: 'text-right',
                },
            ],
            detailRow: AdvancedLicensingRow,
        };
    },
    computed: {
        initialLicensingMap() {
            return this.data.reduce((acc, item) => ({
                ...acc,
                [item.bundleId]: item,
            }), {});
        },
        customerId: function () {
            return this.$route.params.customerId;
        },
    },
    watch: {
        data: {
            handler: function () {
                // update licensing with customer checked settings on init
                this.licensing = this.prepareInitialLicensing();
            },
        },
        customerBundles: {
            handler: function () {
                this.licensing = this.prepareInitialLicensing();
            },
            deep: true,
        },
        licensing: {
            handler: function () {
                const licensing = this.licensing
                    .filter(l => l.checked)
                    .map(({bundleId, options: attributes}) => ({bundleId, attributes}));
                this.$emit('update', licensing);
            },
            deep: true,
        },
    },
    methods: {
        prepareInitialLicensing() {
            return this.customerBundles.map(({
                                                 bundleId,
                                                 bundleName,
                                                 apps,
                                                 numberOfCommunities,
                                                 communities,
                                                 type,
                                                 defaultOptions,
                                             }) => ({
                bundleId,
                bundleName,
                checked: Boolean(this.initialLicensingMap[bundleId]),
                disabled: !this.initialLicensingMap[bundleId] && numberOfCommunities === communities.length,
                options: this.initialLicensingMap[bundleId]?.attributes || defaultOptions,
                apps,
                defaultOptions,
                type,
            }));
        },

        onLicensingInput(value, {data, update}) {
            if (value) {
                update(value);
                return;
            }

            const checkedOnInit = Boolean(this.initialLicensingMap[data.bundleId]);

            if (checkedOnInit) {
                this.requestConfirmation({
                    confirmationMessage: `You’re removing license for ${data.bundleName}. After saving changes this will lead to revocation of access for staff users in this community. The action can’t be undone. Restoration of access will have to be made by admin manually for each staff user.`,
                    confirmBtnText: 'proceed with revoking',
                    cancelBtnText: 'cancel',
                    confirmationType: 'warning',
                }).then((confirmed) => {
                    if (confirmed) {
                        update(value);
                    }
                });
            } else {
                update(value);
            }
        },

        onLicensingCheck(value, {data}) {
            const vuetable = this.$refs.list?.$refs.vuetable;
            if (!value && vuetable && vuetable.isVisibleDetailRow(data.bundleId)) {
                vuetable.hideDetailRow(data.bundleId);
            }
            return true;
        },

        fetchCustomerLicensing() {
            Promise.all([
                this.$authDataProvider.getOne('customers', {id: this.customerId}),
                this.$authDataProvider.getList('bundles', {page: 0, size: 999, nonDeleted: true}),
            ]).then(([customer, bundles]) => {
                const bundlesMap = bundles.content.reduce((acc, {id, apps, type}) => ({
                    ...acc,
                    [id]: {apps, type},
                }), {});

                this.customerBundles = (customer?.licenses || []).map((item) => {
                    return {
                        ...item,
                        ...(bundlesMap[item.bundleId] || {}),
                        defaultOptions: item.options,
                    };
                });
            }).catch(error => this.notifyError(error.message));
        },
    },
    mounted() {
        this.fetchCustomerLicensing();
    },
};
</script>
