<template>
  <div>
    <editable-list ref="list" title="leasing agent" titleClass="form-section-title" :data="leasingAgents" :fields="fields" trackBy="name">
      <template v-slot:row-template="">
        <Form :submit="add">
          <template v-slot="props">
            <div class="row-template">
              <div class="form-row">
                <div class="field-name">
                  <TextField
                    name="name"
                    placeholder="name"
                    :validate="required"
                    clearable />
                </div>
                <div class="field-phone">
                  <PhoneInput
                    ref="phone"
                    name="phone"
                    placeholder="phone"
                    :validate="phoneNumber"
                    clearable />
                </div>
              </div>
              <div class="form-row justify-items-stretch">
                <div class="field-email">
                  <TextField
                    name="email"
                    placeholder="email"
                    :validate="[required, email]"
                    clearable />
                </div>
                <row-template-actions class="flex flex-1 justify-end pl-2" @cancel="cancel" :disabled="props.invalid" />
              </div>
            </div>
          </template>
        </Form>
      </template>
      <template v-slot:inline-actions="actionsScope">
        <button
          class="btn-action-warning focus:outline-none"
          type="button"
          @click.stop="remove(actionsScope.index)"
        >
          <icon class="w-5 h-5 mr-1" name="trash"/>
        </button>
      </template>
    </editable-list>
  </div>
</template>

<script>
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import Icon from "@/components/ui/Icon";
  import Form from "@/components/form/Form";
  import TextField from '@/components/form/TextField';
  import PhoneInput from "@/components/form/PhoneInput";
  import UserFieldEditable from "@/components/auth/list/fields/UserFieldEditable";
  import PhoneFieldEditable from "@/components/auth/list/fields/PhoneFieldEditable";
  import EditableList from "@/components/ui/inlineediting/EditableList";
  import RowTemplateActions from '@/components/ui/inlineediting/RowTemplateActions';

  export default {
    name: 'CommunityLeasingAgent',
    components: {
      Form,
      PhoneInput,
      TextField,
      Icon,
      EditableList,
      RowTemplateActions,
    },
    mixins: [
      ValidatorMixin,
    ],
    props: {
      data:{
        type: Array,
        default() {
          return [];
        }
      },
    },
    emits: ['leasing-agent-change'],
    data() {
      return {
        leasingAgents: [],
        fields: [
          {
            name: UserFieldEditable,
            title: 'name and email',
            switch: {
              getName: rowData => rowData.name,
              getEmail: rowData => rowData.email,
            },
            titleClass: 'relative pr-2',
            dataClass: 'pr-2',
            width: '32%',
          },
          {
            name: PhoneFieldEditable,
            title: 'phone',
            titleClass: 'relative px-2',
            dataClass: 'px-2',
            width: '38%',
          },
          {
            name: 'inline-actions',
            title: '',
            titleClass: 'relative px-2',
            dataClass: 'px-2',
            width: '30%',
          }
        ],
      };
    },
    watch: {
      data() {
        this.leasingAgents = this.data || [];
      },
      leasingAgents:  {
        handler: function () {
          this.$emit('leasing-agent-change', this.leasingAgents);
        },
        deep: true,
      }
    },
    methods: {
      add(values){
        this.leasingAgents.push(values);
        this.$refs.list.hideNewRow();
      },
      cancel() {
        this.$refs.list.hideNewRow();
      },
      remove(index) {
        this.leasingAgents.splice(index,1);
      },
    },
    mounted() {}
  };
</script>
<style scoped>
  .row-template :deep(.form-col) {
    @apply mx-0;
  }
  .row-template :deep(.field-name) {
    @apply pl-4 pr-2;
    width: 32%;
  }
  .row-template :deep(.field-phone) {
    @apply px-2;
    width: 38%;
  }
  .row-template :deep(.field-email) {
    @apply pl-4 pr-2;
    width: 32%;
  }
</style>
