<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-role"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-role">
    <editable-field :initialValues="{ role: rowData.role }" :on-submit="updateRole">
      <template v-slot:field="{ editMode }" >
        <SelectInput
          name="role"
          placeholder="select role"
          class="editable-role"
          :editMode="editMode"
          :options="getRoles()"
          :validate="required"
        >
        </SelectInput>
      </template>
    </editable-field>
  </td>
</template>

<script>
  import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin.vue';
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import SelectInput from "@/components/form/SelectInput";
  import EditableField from "@/components/ui/inlineediting/EditableField";

  export default {
    name: 'RoleFieldEditable',
    components: {SelectInput, EditableField},
    mixins: [VuetableFieldMixin, ValidatorMixin],
    methods: {
      getRoles() {
        const getRoles = this.rowField.switch?.getRoles;
        return typeof getRoles === 'function' ? getRoles() : [];
      },
      updateRole({ role }) {
        this.rowData.role = role;
      },
    }
  };
</script>
<style scoped>
  .editable-role {
    max-width: 13.75rem;
  }
</style>
