<template>
  <div>
    <editable-list ref="list" title="community management" titleClass="form-section-title" :data="management" :fields="fields" trackBy="name">
      <template v-slot:row-template="">
        <Form :submit="add">
          <template v-slot="props">
            <div class="row-template">
              <div class="form-row">
                <div class="field-name">
                  <TextField name="name" placeholder="name" :validate="required" clearable />
                </div>
                <div class="field-phone">
                  <PhoneInput ref="phone" name="phone" placeholder="phone" :validate="phoneNumber" clearable />
                </div>
                <div class="field-role">
                  <SelectInput name="role" placeholder="role" :options="managementRoles" :validate="required" />
                </div>
              </div>
              <div class="form-row">
                <div class="field-email">
                  <TextField name="email" placeholder="email" :validate="[required, email]" clearable />
                </div>
              </div>
              <row-template-actions class="form-row justify-end pl-2" @cancel="cancel" :disabled="props.invalid" />
            </div>
          </template>
        </Form>
      </template>
      <template v-slot:inline-actions="actionsScope">
        <button
          class="btn-action-warning focus:outline-none"
          type="button"
          @click.stop="remove(actionsScope.index)"
        >
          <icon class="w-5 h-5 mr-1" name="trash"/>
        </button>
      </template>
    </editable-list>
  </div>
</template>

<script>
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import Icon from "@/components/ui/Icon";
  import Form from "@/components/form/Form";
  import TextField from '@/components/form/TextField';
  import PhoneInput from "@/components/form/PhoneInput";
  import SelectInput from "@/components/form/SelectInput";
  import UserFieldEditable from "@/components/auth/list/fields/UserFieldEditable";
  import PhoneFieldEditable from "@/components/auth/list/fields/PhoneFieldEditable";
  import RoleFieldEditable from "@/components/auth/list/fields/RoleFieldEditable";
  import EditableList from "@/components/ui/inlineediting/EditableList";
  import RowTemplateActions from '@/components/ui/inlineediting/RowTemplateActions';

  export default {
    name: 'CommunityManagement',
    components: {
      Icon,
      Form,
      PhoneInput,
      TextField,
      SelectInput,
      EditableList,
      RowTemplateActions,
    },
    mixins: [
      ValidatorMixin,
      NotifyMixin,
    ],
    props: {
      data:{
        type: Array,
        default() {
          return [];
        }
      },
    },
    emits: ['management-change'],
    data() {
      return {
        management: [],
        managementRoles: [],
        fields: [
          {
            name: UserFieldEditable,
            title: 'name and email',
            switch: {
              getName: rowData => rowData.name,
              getEmail: rowData => rowData.email,
            },
            titleClass: 'relative pr-2',
            dataClass: 'pr-2',
            width: '32%'
          },
          {
            name: PhoneFieldEditable,
            title: 'phone',
            titleClass: 'relative px-1',
            dataClass: 'px-1',
            width: '32%'
          },
          {
            name: RoleFieldEditable,
            title: 'role',
            titleClass: 'relative px-1',
            dataClass: 'relative px-1',
            width: '30%',
            switch: {
              getRoles: () => this.managementRoles
            },
          },
          {
            name: 'inline-actions',
            title: '',
            titleClass: 'relative px-1',
            dataClass: 'px-1',
            width: '6%',
          }
        ],
      };
    },
    computed: {
      managementRolesMap() {
        return this.managementRoles.reduce(
           (acc, { value, key }) => ({...acc, [key]: value }),
           {}
         );
      }
    },
    watch: {
      management:  {
        handler: function () {
          this.$emit('management-change', this.management);
        },
        deep: true,
      },
      data(newValue) {
        this.setTransformedData(newValue);
      },
      managementRoles() {
        this.setTransformedData(this.management);
      }
    },
    methods: {
      add(values){
        this.management.push({
          ...values,
          roleDescription: this.managementRolesMap[values.role] || values.role,
        });
        this.$refs.list.hideNewRow();
      },
      cancel() {
        this.$refs.list.hideNewRow();
      },
      remove(index) {
        this.management.splice(index,1);
      },
      setTransformedData(data) {
        if (data) {
          this.management = data.map((item) => ({
            ...item,
            roleDescription: this.managementRolesMap[item.role] || item.role
          }));
        }
      },
      fetchManagementRoles() {
        this.$authDataProvider.getList('managementRoles')
          .then(content => {
            this.managementRoles = content.map(({ value, description }) => ({
              value: description.toLowerCase(),
              key: value,
            }));
          })
          .catch((err) => this.notifyError(err))
      },
    },
    mounted() {
      this.fetchManagementRoles();
    }
  };
</script>
<style scoped>
  .row-template :deep(.form-col) {
    @apply mx-0;
  }
  .row-template :deep(.field-name) {
    @apply pl-4 pr-2;
    width: 32%;
  }
  .row-template :deep(.field-phone) {
    @apply px-2;
    width: 32%;
  }
  .row-template :deep(.field-role) {
    @apply px-2;
    width: 36%;
  }
  .row-template :deep(.field-email) {
    @apply pl-4 pr-2;
    width: 32%;
  }
</style>
