<template>
    <div>
        <span v-if="!noTitle" class="section-title text-highlight-base text-base font-frank font-600 block mb-2">
            hours of operation
        </span>
        <div v-if="!operation_hours.length && !disabled" class="mt-4">
            <div class="btn-primary inline inline-flex items-center cursor-pointer" @click="addNewOperationHours">
                add hours
                <icon name="plus" class="h-4 w-4 ml-3"></icon>
            </div>
        </div>
        <div v-else-if="!operation_hours.length && disabled" class="mt-4">
          <div class="font-semibold font-inter text-blue-700">
            No hours of operations data available
          </div>
        </div>
        <div
            v-for="(operation,index) in operation_hours"
            :key="index"
            class="mb-8"
        >
            <div class="flex justify-between items-center mb-2">
                <div class="w-3/12 mr-4">
                    <div :class="['form-col', disabled ? 'pointer-events-none' : '']">
                        <label class="text-black text-xs font-normal mb-1 block" >day(s)</label>
                        <slot name="operation-days" :value="operation.description" :update="(data) => { operation.description = data}">
                            <text-input v-model="operation.description" :disabled="disabled" />
                        </slot>
                    </div>
                </div>
                <div class="mr-4">
                    <div :class="['form-col', disabled ? 'pointer-events-none' : '']">
                        <label class="text-black text-xs font-normal mb-1 block">open time</label>
                        <time-picker-input
                        :value="operation.openingTime"
                        @time-picked="updateOperation($event, index, 'openingTime')"
                        :closed="operation.is_closed"
                        />
                    </div>
                </div>
                <div class="mr-4">
                    <div :class="['form-col', disabled ? 'pointer-events-none' : '']">
                        <label class="text-black text-xs font-normal mb-1 block">close time</label>
                        <time-picker-input
                        :value="operation.closingTime"
                        @time-picked="updateOperation($event, index, 'closingTime')"
                        :closed="operation.is_closed"
                        />
                    </div>
                </div>
                <div class="mr-4">
                    <div :class="['form-col', disabled ? 'pointer-events-none' : '']">
                        <label class="text-black text-xs font-normal mb-1 block">closed</label>
                        <toggle-input v-model="operation.is_closed" class="mt-1" />
                    </div>
                </div>
                <div class="flex-col items-center justify-center" v-if="!disabled">
                    <icon
                        name="plusCircle"
                        class="w-4 h-4 min-w-4 text-active-500 cursor-pointer mb-2"
                        @click="addNewOperationHours"
                    />
                    <icon
                        name="minusCircle"
                        class="w-4 h-4 min-w-4 text-graphite-800 cursor-pointer"
                        @click="removeOperationHours(index)"
                    />
                </div>
            </div>
            <slot name="message" :value="operation.description"></slot>
            <div v-if="resourcesAvailable" class="w-full pt-4 block">
              <div class="form-col">
                <label class="text-black text-xs font-normal mb-1 block" >Resources Available</label>
                <input id="resourcesAvailable" class="form-input" size="2" type="number" min="0" step="1" v-model="operation.resources_available" />
              </div>
            </div>            
        </div>
    </div>
</template>

<script>
    import ToggleInput from "@/components/ui/ToggleInput";
    import TimePickerInput from "@/components/ui/TimePickerInput";
    import Icon from "@/components/ui/Icon";
    import TextInput from '@/components/ui/TextInput';
    import NotifyMixin from "@/mixins/NotifyMixin";
    import {getTimeObjectFromString, getTimeStringFromObject} from "@/utils/Schedule";

    export default {
      components: { ToggleInput, TimePickerInput, Icon, TextInput },
      mixins: [ NotifyMixin ],
      props: {
          noTitle: {
              required: false,
              type: Boolean,
              default: false
          },
          disabled: {
              required: false,
              type: Boolean,
              default: false
          },
          websiteOperationHours: {
              required: false,
              type: Array,
              default: () => []
          },
          template: {
              required: false,
              type: Object,
              default: () => {}
          },
          resourcesAvailable: {
              required: false,
              type: Boolean,
              default: false
          }          
      },
      emits: ['operation-hours-change'],
      data: () => {
          return {
              operation_hours: []
          }
      },
      computed: {
          skeleton() {
              return {
                  description: 'Monday - Friday',
                  is_closed: false,
                  openingTime: {
                      hours: '09',
                      minutes: '00',
                      clock: 'am'
                  },
                  closingTime: {
                      hours: '06',
                      minutes: '00',
                      clock: 'pm'
                  },
                  open: '09:00 am',
                  close: '06:00 pm',
              }
          }
      },
      methods: {
          addNewOperationHours() {
              this.operation_hours.push( Object.assign({}, this.skeleton, this.template));
          },
          removeOperationHours(index) {
              this.operation_hours.splice(index, 1);
          },
          updateOperation(payload, index, property) {
              this.operation_hours[index][property] = payload;

              if(property === 'openingTime')
                  this.operation_hours[index]['open'] = getTimeStringFromObject(this.operation_hours[index][property]);

              if(property === 'closingTime')
                  this.operation_hours[index]['close'] = getTimeStringFromObject(this.operation_hours[index][property]);
            },
        },
        watch: {
            websiteOperationHours: {
                handler: function() {
                    try{
                        this.websiteOperationHours.forEach(operation => {
                            operation.openingTime = getTimeObjectFromString(operation.open);
                          operation.closingTime = getTimeObjectFromString(operation.close);
                        });
                        this.operation_hours = this.websiteOperationHours;
                    } catch (e) {
                        this.notifyError('Fail to fetch Hours Operations', e)
                    }

              },
              deep: true,
          },
          operation_hours:  {
              handler: function () {
                  this.$emit('operation-hours-change', this.operation_hours);
              },
              deep: true,
          }
      }
    }
</script>
<style scoped>
  #resourcesAvailable{
    width: 70px;
  }
</style>