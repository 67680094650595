<template>
  <Form ref="form" :submit="handleFormSubmit" :initialValues="initialValues" class="community-form">
    <template v-slot="props">
      <div class="form-narrow flex h-full">
        <DetailsSidePanel class="side-panel side-panel-gray flex-shrink-0">
          <template v-slot:heading>community description</template>
          <template v-slot:text>
            <div class="flex-column space-y-2 mt-4">
              <div
                v-for="(tab, index) in tabs"
                class="tab"
                :class="{active: activeTab === tab.key}"
                :key="index"
                @click="activeTab = tab.key"
              >
                <span class="flex-grow">{{ tab.title }}</span>
                <Icon v-if="errorTabs[tab.key]" name="warning" class="w-4 h-4 flex-shrink-0 text-red-500" />
              </div>
            </div>
          </template>
        </DetailsSidePanel>
        <div class="w-2/3 pb-8 overflow-auto relative">

          <div v-show="activeTab === 'main'">
            <label class="form-section-title">basic info</label>
            <div v-if="communityId" class="form-row items-end" :style="{display: 'inline-flex', marginBottom: '1.5rem'}">
              <TextField name="id" label="community id" :edit-mode="false" />
              <clipboard-button
                  class="focus:outline-none copy-id-btn"
                  :value="initialValues.id"
              ></clipboard-button>
            </div>
            <div class="form-row">
              <TextField name="name" label="community name*" :validate="required" />
              <TextField name="ownerName" label="community owner name*" :validate="required" />
            </div>
            <div class="form-row">
              <TextField name="description" label="description" />
            </div>
            <div class="h-4"></div>
            <div class="form-row">
              <PhoneInput name="phone" label="phone*" :validate="[required, phoneNumber]" />
              <PhoneInput name="smsPhone" label="sms number*" :validate="[required, phoneNumber]" />
            </div>
            <div class="h-4"></div>
            <div class="form-row">
              <TextField
                name="email"
                label="community email*"
                :validate="[required, email]"
                hint="generic email for community communications"
              />
              <TextField
                name="leasingEmail"
                label="community leasing email"
                :validate="email"
                hint="generic email for community leasing/marketing communications"
              />
            </div>
            <div class="form-row w-1/2 pr-4">
              <TextField
                name="onlinePaymentEmail"
                label="online payment email"
                :validate="email"
                hint="generic email for all online payment receipts and issues"
              />
            </div>
            <div class="h-4"></div>
            <div class="form-row w-1/2 pr-4">
              <SelectInput
                name="timezoneId"
                label="timezone*"
                :options="timezones"
                :options-limit="100"
                :validate="required"
              />
            </div>
            <div class="h-4"></div>
            <label class="form-section-title">mailing address</label>
            <div class="form-row">
              <TextField
                name="mailingAddress.city"
                label="city*"
                :validate="required"
              />
              <StateInput
                name="mailingAddress.state"
                label="state*"
                :validate="required"
              />
              <TextField name="mailingAddress.zip" label="zip*" :validate="required" />
            </div>
            <div class="form-row">
              <TextField
                name="mailingAddress.addressLine1"
                label="street line 1*"
                :validate="required"
              />
            </div>
            <div class="form-row">
              <TextField name="mailingAddress.addressLine2" label="street line 2" />
            </div>
            <div class="h-4"></div>
            <label class="form-section-title">physical address</label>
            <div class="form-row">
              <CheckboxInput name="sameAsMailingAddress" label="same as mailing address" />
              </div>
            <div v-if="!props.values.sameAsMailingAddress">
              <div class="form-row">
                <TextField name="address.city" label="city*" :validate="required" />
                <StateInput name="address.state" label="state*" :validate="required" />
                <TextField name="address.zip" label="zip*" :validate="required" />
              </div>
              <div class="form-row">
                <TextField
                  name="address.addressLine1"
                  label="street line 1*"
                  :validate="required"
                />
              </div>
              <div class="form-row">
                <TextField name="address.addressLine2" label="street line 2" />
              </div>
            </div>
          </div>

          <div v-show="activeTab === 'licensing'">
            <Authorize :has-role="['SUPER_ADMIN', 'SUPER_READONLY_ADMIN']">
              <label class="form-section-title flex-col">
                <span>licensing options</span>
                <span class="text-xs">select applications this community should have access to</span>
              </label>
              <CommunityLicensing :data="initialValues.licenseBindings" @update="updateLicensing"></CommunityLicensing>
            </Authorize>
          </div>

          <div v-show="activeTab === 'social'">
            <label class="form-section-title">social links</label>
            <div class="form-row">
              <TextField
                name="websiteUrl"
                label="community url"
                :validate="safeUrl"
                hint="primary website that prospects would visit"
              />
              <TextField
                name="corporateUrl"
                label="corporate url"
                :validate="safeUrl"
                hint="primary website for the customer/management company"
              />
            </div>
            <div class="form-row">
              <TextField name="facebookUrl" label="facebook url" :validate="safeUrl" />
              <TextField name="twitterUrl" label="twitter url" :validate="safeUrl" />
            </div>
            <div class="form-row">
              <TextField name="instagramUrl" label="instagram url" :validate="safeUrl" />
              <TextField name="pinterestUrl" label="pinterest url" :validate="safeUrl" />
            </div>
          </div>

          <div v-show="activeTab === 'community-contacts'">
            <community-management
              :data="initialValues.communityManagement"
              @management-change="updateManagement"
            ></community-management>
            <div class="h-4"></div>
            <community-leasing-agent
              :data="initialValues.leasingAgent"
              @leasing-agent-change="updateLeasingAgent"
            ></community-leasing-agent>
          </div>

          <div v-show="activeTab === 'operation-hours'">
            <label class="form-section-title">hours of operation</label>
            <hours-of-operations
              :website-operation-hours="initHours"
              @operation-hours-change="updateHours"
              :template="{ description: null }"
              no-title
            >
              <template v-slot:operation-days="{ value, update }">
                <dropdown
                  placeholder="select a day"
                  :options="getOperationDayOptions(value)"
                  :value="value"
                  @input="update"
                ></dropdown>
              </template>
              <template v-slot:message="{ value }">
                <span class="form-error" v-if="dayErrors.includes(value)">this time range is incorrect, please enter a correct time range</span>
              </template>
            </hours-of-operations>
            </div>

            <div v-show="activeTab === 'logo'">
            <div v-if="!filesLoading"> <!-- TODO: wait community files loaded. Remove when FileManager starts watching library files? -->
              <label class="form-section-title">community logo upload</label>
              <InfoText v-if="!initialValues.id">please save community before uploading any images.</InfoText>
              <file-manager
                v-else
                :main-directory="'auth'"
                :library="getLibraryImageFilesByBaseDirectory('auth')"
                :max-upload-size="20"
                :max-nb-of-files="1"
                :cropper-options="{aspectRatio: 1, cropBoxResizable: true, zoomOnWheel: false}"
                v-model="communityLogoId"
                :community-id="initialValues.id"
                :key="'logo-'+ initialValues.communityLogoId"
              ></file-manager>

              <div class="h-4"></div>

              <label class="form-section-title flex-col">
                <span>community image upload</span>
                <span class="text-xs">primary photo used as a backdrop</span>
              </label>
              <InfoText v-if="!initialValues.id">please save community before uploading any images.</InfoText>
              <file-manager
                v-else
                :main-directory="'auth'"
                :library="getLibraryImageFilesByBaseDirectory('auth')"
                :max-nb-of-files="1"
                :max-upload-size="20"
                :cropper-options="{aspectRatio: 16 / 9, cropBoxResizable: true, zoomOnWheel: false}"
                v-model="communityImageId"
                :community-id="initialValues.id"
                :key="'image-'+ initialValues.communityImageId"
              ></file-manager>
            </div>
          </div>

          <div v-show="activeTab === 'integrations'">
            <label class="form-section-title">virtual tour</label>
            <div class="form-row">
              <TextField name="floorplansUrl" label="floorplans url" :validate="safeUrl" />
              <TextField name="virtualTourUrl" label="virtual tour url" :validate="safeUrl" />
            </div>
            <div class="form-row">
              <SelectInput
                name="tourSchedulingModel"
                label="tour scheduling model"
                optionLabel="description"
                :options="tourSchedulingTypes"
              />
              <SelectInput name="tourDuration" label="tour duration" :options="tourDuration" />
            </div>
            <div class="h-4"></div>
            <label class="form-section-title">integrations</label>
            <InfoText v-if="communityId">
              integrations are coming soon. Please check
              <router-link class="text-blue-500 ml-1" :to="{name: integrationBasePath, params: {customerId: customerId, communityId: communityId}}">
                manage integration partners
              </router-link>
            </InfoText>
            <InfoText v-else>
              please save community before adding integration partners
            </InfoText>
          </div>
        </div>

        <ModalFooter :footer="footer" :tertiary="cancel" />
      </div>
    </template>
  </Form>
</template>

<script>
  import { mapGetters } from "vuex";
  import ValidatorMixin from "@/components/form/ValidatorMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Authorize from "@/components/auth/Authorize";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import Dropdown from "@/components/ui/Dropdown";
  import Icon from "@/components/ui/Icon";
  import InfoText from "@/components/ui/InfoText";
  import Form from "@/components/form/Form";
  import TextField from "@/components/form/TextField";
  import CheckboxInput from "@/components/auth/form/CheckboxInput";
  import StateInput from "@/components/form/StateInput";
  import PhoneInput from "@/components/form/PhoneInput";
  import SelectInput from "@/components/form/SelectInput";
  import ModalFooter from "@/components/ui/modals/ModalFooter";
  import FileManager from "@/components/ui/filemanager/FileManager";
  import HoursOfOperations from "@/components/operation_hours/HoursOfOperations";
  import CommunityManagement from "@/views/auth/customers/communities/CommunityManagement";
  import CommunityLeasingAgent from "@/views/auth/customers/communities/CommunityLeasingAgent";
  import CommunityLicensing from "@/views/auth/customers/communities/CommunityLicensing";
  import ClipboardButton from "@/components/auth/list/ClipboardButton";

  const weekDays = ['Monday','Tuesday','Wednesday','Thursday','Friday'];
  const weekends = ['Saturday', 'Sunday'];

  // validate fields
  const mainFields = ['name', 'ownerName', 'phone', 'smsPhone', 'email', 'leasingEmail', 'onlinePaymentEmail',
    'timezoneId', 'address', 'mailingAddress', 'mailingAddress.city', 'mailingAddress.state', 'mailingAddress.zip', 'mailingAddress.addressLine1' ];
  const socialFields = ['websiteUrl', 'corporateUrl', 'facebookUrl', 'twitterUrl', 'instagramUrl', 'pinterestUrl'];
  const integrationsFields = ['floorplansUrl', 'virtualTourUrl', 'tourSchedulingModel' ];

  export default {
    name: "CommunityForm",
    components: {
      Icon,
      Authorize,
      ClipboardButton,
      DetailsSidePanel,
      CommunityLicensing,
      HoursOfOperations,
      Dropdown,
      InfoText,
      SelectInput,
      ModalFooter,
      Form,
      PhoneInput,
      StateInput,
      CheckboxInput,
      TextField,
      FileManager,
      CommunityManagement,
      CommunityLeasingAgent,
    },
    mixins: [
      ValidatorMixin,
      NotifyMixin,
    ],
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      initialValues: {
        type: Object,
        default: () => ({}),
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
      filesLoading: {
        type: Boolean,
        required: false,
        default: false,
      },
      integrationBasePath: {
        type: String,
        required: true,
      }
    },
    emits:['close'],
    computed: {
      ...mapGetters({
        isQuextAdmin: "auth/isQuextAdmin",
        getLibraryImageFilesByBaseDirectory: "files/getLibraryImageFilesByBaseDirectory",
        getThumbnailUrlByFileId: "files/getThumbnailUrlByFileId",
      }),
      customerId: function () {
        return this.$route.params.customerId;
      },
      communityId: function () {
        return this.$route.params.communityId;
      },
      initHours() {
        return this.initialValues.workingHours?.map(({ isClosed, ...record }) => ({ ...record, is_closed: isClosed })) || [];
      },
      availableOperationDays() {
        const selectedDays = this.hours?.filter(hour => hour.description).map(hour => hour.description) || [];

        let excludedDays = [...selectedDays];
        const selectedWeekDay = weekDays.find(weekDay => selectedDays.includes(weekDay));
        const selectedWeekend = weekends.find(weekend => selectedDays.includes(weekend));
        if (selectedDays.includes('Monday - Friday')) {
          excludedDays = [...excludedDays, ...weekDays];
        }
        if (selectedWeekDay) {
          excludedDays = [...excludedDays, 'Monday - Friday'];
        }
        if (selectedDays.includes('Weekends')) {
          excludedDays = [...excludedDays, ...weekends];
        }
        if (selectedWeekend) {
          excludedDays = [...excludedDays, 'Weekends'];
        }
        return this.operationDays.filter(day => !excludedDays.includes(day.value));
      },
      tabs() {
        return [
          { key: "main", title: "basic info" },
          { key: "licensing", title: "licensing", hidden: !this.isQuextAdmin },
          { key: "community-contacts", title: "community contacts" },
          { key: "operation-hours", title: "hours of operations" },
          { key: "social", title: "social links" },
          { key: "integrations", title: "integrations" },
          { key: "logo", title: "community image" },
        ].filter(i => !i.hidden);
      },
      tourDuration() {
        return [ 0, 15, 30, 45 ].map((item) => ({key: item, value: item}));
      },
    },
    data() {
      return {
        activeTab: "main",
        errorTabs: {},
        footer: {
          primaryButton: 'Save',
          tertiaryButton: 'cancel'
        },
        communityImageId: [],
        communityLogoId: [],
        communityManagement: [],
        leasingAgent: [],
        licensing: [],
        hours: [],
        operationDays: [],
        timezones: [],
        tourSchedulingTypes: [],
        dayErrors: [],
      };
    },
    watch: {
      'initialValues.communityLogoId': {
        handler: function (newValue) {
          this.communityLogoId = [newValue];
        },
        immediate: true
      },
      'initialValues.communityImageId': {
        handler: function (newValue) {
          this.communityImageId = [newValue];
        },
        immediate: true
      },
    },
    methods: {
      fetchTimeZones() {
        this.$authDataProvider.getList('timezones')
          .then(content => {
            this.timezones = content.map(({code}) => ({key: code, value: code}));
          })
          .catch((err) => this.notifyError(err))
      },

      fetchTourSchedulingTypes() {
        this.$authDataProvider.getList('tourSchedulingTypes')
          .then(content => {
            this.tourSchedulingTypes = content.map(({value, description}) => ({key: value, description, value}));
          })
          .catch((err) => this.notifyError(err))
      },

      fetchWeekday() {
        this.$authDataProvider
          .getList("weekdays")
          .then((result) => {
            this.operationDays = result.map((value) => ({
              value,
              key: value,
            }));
          })
          .catch((err) => this.notifyError(err));
      },

      updateHours(payload) {
        this.hours = payload;
        if (this.errorTabs['operation-hours']) {
          this.errorTabs['operation-hours'] = false;
        }
      },

      updateManagement(payload) {
        this.communityManagement = payload;
      },

      updateLeasingAgent(payload) {
        this.leasingAgent = payload;
      },

      updateLicensing(data) {
          this.licensing = data;
      },

      validateDay(value) {
        if(value.isClosed) return false;
        const { openingTime: ot, closingTime:  ct} = value;
        if(ot.clock == 'pm' && ct.clock == 'am') return true
        if(ot.clock == ct.clock && (ot.hours > ct.hours)) return true; 
        return false;
      },

      handleFormSubmit({ sameAsMailingAddress, ...values}) {
        this.dayErrors = [];
        const communityLogoId = this.communityLogoId[0] || null;
        const communityLogoUrl = communityLogoId ? this.getThumbnailUrlByFileId(communityLogoId) : "";

        const communityImageId = this.communityImageId[0] || null;
        const communityImageUrl = communityImageId
          ? this.getThumbnailUrlByFileId(communityImageId)
          : "";

        const workingHours = this.hours.map(({ is_closed, ...record }) => ({
          ...record,
          isClosed: is_closed,
        }));

        if (workingHours.some((item) => !item.description?.length)) {
          this.errorTabs['operation-hours'] = true;
          this.notifyError(
            "You must specify the days for all hours of operations."
          );
          return;
        }

        const invalidDays =  workingHours.filter(day => this.validateDay(day)).map(day => day.description);

        if(invalidDays.length > 0) {
          this.errorTabs['operation-hours'] = true;
          this.dayErrors = invalidDays;
          return;
        }

          this.onSubmit({
            ...values,
            communityLogoId,
            communityLogoUrl,
            communityImageId,
            communityImageUrl,
            workingHours,
            address: sameAsMailingAddress ? values.mailingAddress : values.address,
            communityManagement: this.communityManagement,
            leasingAgent: this.leasingAgent,
            licenseBindings: this.licensing,
          });
      },

      cancel(e) {
        e.preventDefault();
        this.$emit('close');
      },

      getOperationDayOptions(value) {
        if (!value) {
          return this.availableOperationDays;
        }

        if (value === 'Monday - Friday') {
          return [value, ...weekDays].map((d) => ({ key: d, value: d })).concat(this.availableOperationDays);
        }

        if (value === 'Weekends') {
          return [value, ...weekends].map((d) => ({ key: d, value: d })).concat(this.availableOperationDays);
        }

        return [{ key: value, value}, ...this.availableOperationDays];
      },
    },
    created() {
      this.formValues = this.initialValues;
    },
    mounted() {
      this.$watch(
          () => this.$refs.form?.formState.errors,
          (errors = {}) => {
            const errorFields = Object.keys(errors);
            this.errorTabs['main'] = errorFields.some((item) => mainFields.includes(item));
            this.errorTabs['social'] = errorFields.some((item) => socialFields.includes(item));
            this.errorTabs['integrations'] = errorFields.some((item) => integrationsFields.includes(item));
          });

      Promise.all([
        this.fetchTimeZones(),
        this.fetchTourSchedulingTypes(),
        this.fetchWeekday(),
      ]);
    },
  };
</script>
<style scoped>
  .community-form,
  .community-form :deep(form) {
    @apply h-full;
  }
  .tab {
    @apply flex flex-grow border border-gray-300 p-2 cursor-pointer text-black;
  }

  .tab.active {
    @apply border-blue-500 text-blue-500;
  }

  .tab.error {
    @apply border-red-500 text-red-500;
  }

  .copy-id-btn {
    margin-left: -0.5rem; /* reduce form-col offset */
    margin-bottom: 0.125rem;
  }
</style>
