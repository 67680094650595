<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-username"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-username">
    <editable-field :initialValues="{ name: getName(rowData) }" editClass="mb-1" :on-submit="updateName">
      <template v-slot:field="{ editMode, formProps }">
        <TextField
          name="name"
          :editMode="editMode"
          textClass="text-sm font-bold"
          size="small"
          :validate="required"
          :clearable="editMode && Boolean(formProps.values.name)"
          @clear="formProps.change('name', '')"
        />
      </template>
    </editable-field>
    <editable-field :initialValues="{ email: getEmail(rowData) }" editClass="mt-1" :on-submit="updateEmail">
      <template v-slot:field="{ editMode, formProps }">
        <TextField
          name="email"
          :editMode="editMode"
          textClass="text-sm text-gray-600"
          size="small"
          :validate="[required, email]"
          :clearable="editMode && Boolean(formProps.values.email)"
        />
      </template>
    </editable-field>
  </td>
</template>

<script>
  import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin.vue';
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import TextField from "@/components/form/TextField";
  import EditableField from "@/components/ui/inlineediting/EditableField";

  export default {
    name: 'UserFieldEditable',
    components: {TextField, EditableField},
    mixins: [VuetableFieldMixin, ValidatorMixin],
    methods: {
      getName(rowData) {
        const getName = this.rowField.switch?.getName;
        return getName ? getName(rowData) : `${rowData.firstName} ${rowData.lastName}`;
      },
      getEmail(rowData) {
        const getEmail = this.rowField.switch?.getEmail;
        return getEmail ? getEmail(rowData) : rowData.email;
      },
      updateName({ name }) {
        this.rowData.name = name;
      },
      updateEmail({ email }) {
        this.rowData.email = email;
      },
    }
  };
</script>
