<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-phone"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-phone">
    <editable-field
        ref="field"
        :initialValues="{ phone: rowData.phone }"
        :on-submit="updatePhone"
        :close-on-submit="closeOnSubmit"
        :no-click-outside="noClickOutside"
    >
      <template v-slot:field="{ editMode, formProps }" >
        <PhoneInput
          name="phone"
          :editMode="editMode"
          textClass="text-sm whitespace-nowrap whitespace-no-wrap"
          :validate="fieldValidator"
          ref="phone"
          :clearable="editMode && Boolean(formProps.values.phone)"
        ></PhoneInput>
      </template>
    </editable-field>
  </td>
</template>

<script>
  import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin.vue';
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import PhoneInput from "@/components/form/PhoneInput";
  import EditableField from "@/components/ui/inlineediting/EditableField";

  export default {
    name: 'PhoneFieldEditable',
    components: {PhoneInput, EditableField},
    mixins: [VuetableFieldMixin, ValidatorMixin],
    computed: {
      closeOnSubmit() {
        return typeof this.rowField.switch?.onUpdate !== 'function';
      },
      fieldValidator() {
        const getValidator = this.rowField.switch?.getValidator;
        return getValidator ? getValidator(this.rowData) : [this.required, this.phoneNumber];
      },
      noClickOutside() {
        return this.rowField.switch?.noClickOutside;
      },
    },
    methods: {
      updatePhone({ phone }) {
        const onUpdate = this.rowField.switch?.onUpdate;

        if (typeof onUpdate === 'function') {
          onUpdate(phone, { data: this.rowData, index: this.rowIndex, ref: this.$refs.field });
        } else {
          this.rowData.phone = phone;
        }
      },
    }
  };
</script>
