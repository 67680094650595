<template>
  <Details
    ref="details"
    title="Community"
    resource="customerCommunities"
    :base-path="basePath"
    routeIdParam="communityId"
    :request-params="{customerId: customerId}"
    title-template="{name} community"
  >
    <template v-slot:default="detailsProps">
      <CommunityForm
        :initialValues="getInitialValues(detailsProps.record)"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        :files-loading="communityFilesLoading"
        :integrationBasePath="integrationBasePath"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import {mapActions} from "vuex";
  import {isEqual} from "lodash-es";
  import ActionsMixin from "@/mixins/ActionsMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import AuthMixin from "@/components/auth/AuthMixin";
  import Details from '@/components/auth/details/Details';
  import CommunityForm from '@/views/auth/customers/communities/CommunityForm';

  export default {
    name: 'CommunitiesEdit',
    components: {
      CommunityForm,
      Details,
    },
    mixins: [ActionsMixin, AuthMixin, NotifyMixin],
    computed: {
      customerId: function () {
        return this.$route.params.customerId;
      },
      basePath: function () {
        return this.$route.name.replace('edit', 'index');
      },
      integrationBasePath: function () {
        return this.$route.name.replace('edit', 'integrations.index');
      }
    },
    data() {
      return {
        communityFilesLoading: false,
      }
    },
    methods: {
      ...mapActions({
        clearLibrary: 'files/clearLibrary',
        loadFilesLibraryInStore: 'files/loadLibrary'
      }),
      getInitialValues(initialValues) {
        return {
          ...initialValues,
          sameAsMailingAddress: isEqual(initialValues.mailingAddress, initialValues.address)
        }
      },
      deleteCommunity() {
        this.$authDataProvider.delete('customerCommunities', {id: this.$route.params.communityId, customerId: this.customerId})
          .then(() => {
            this.clearLibrary();
            this.notifySuccess('community deleted');
            this.$refs.details?.redirectToList();
          })
          .catch(error => this.notifyError(error.message))
      },
      fetchCommunityFilesLibrary() {
        this.communityFilesLoading = true;
        this.$filemanagerDataProvider.get('communityFiles', { communityId: this.$route.params.communityId })
          .then( (response) => {
            let files = [];
            response?.forEach( file => {
              files.push({
                base_directory: file.base_directory,
                original_name: file.file_name,
                file_uuid: file.file_uuid,
                thumbnail_url: file.thumbnail_url,
                file_url: file.file_url,
                file_type: file.file_type,
                file_size: file.file_size,
                created_at: file.created_at,
                updated_at: file.updated_at,
                tags: [],
              });
            });
            this.loadFilesLibraryInStore(files);
          })
          .catch((error) => this.notifyError(error.message))
          .finally(() => { this.communityFilesLoading = false; })
      },
    },
    created() {
      this.fetchCommunityFilesLibrary();
    },
    mounted() {
      this.$watch('$refs.details.record', function (record) {
        const {deletedAt} = record;

        if (!deletedAt && this.isQuext) {
          this.addConfirmAction(
            {
              id: 'auth-delete-community',
              title: "delete community",
              showConfirmation: true,
              confirmationMessage: 'Are you sure you want to delete this community?',
              confirmBtnText: 'yes',
              cancelBtnText: 'no',
              confirmationType: 'warning'
            },
            this.deleteCommunity
          )
        }
      });
    }
  };
</script>
